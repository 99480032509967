@import "../../../global.scss";

.section__background {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    color: $mainColor;
    padding: 0 1rem;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    .section__half {
        display: block;
        padding: 1em 0;
    }

    .section__whoIAm {
        .whoIAm {
            width: 100%;
            margin: 0 auto;
            padding: 0 0.5rem;
            .section__subtitle--about {
                font-weight: 300;
            }
            .section__subtitle {
                color: $secondaryColor;
                font-size: 1.2em;
                background-color: $mainColor;
                padding: 0.25em 0.5em;
                margin-bottom: 10px;
                font-weight: 400;
                display: inline-block;
            }
            .background__body {
                color: black;
            }
            .background__img {
                float: right;
                max-height: 400px;
                margin-left: 1em;
            }
            @media (min-width: $tablet) {
                display: grid;
                grid-template-columns: 1fr 150px;
                grid-template-rows: 100px 50px 1fr;
                grid-template-areas:
                    "title img"
                    "subtitle img"
                    "text img";
                grid-column-gap: 2em;

                .section__title--about {
                    grid-area: title;
                    grid-column: 1;
                    line-height: 2em;
                }
                .section__subtitle--about {
                    line-height: 1.6em;
                    padding-right: 100px;
                    align-self: start;
                    grid-column: -1/1;
                    grid-row: 2;
                    position: relative;
                    left: -1.5em;
                    padding-left: 1.5em;
                    width: calc(100% + 4em);
                    z-index: -1;
                }
                .background__img {
                    grid-area: img;
                }
            }
        }
    }
    .section__moreAboutMe {
        clear: right;
        .section__title {
            text-align: center;
        }
    }
}
