@import "../../../global.scss";

.section__about-me {
    text-align: center;
    position: relative;
    color: $secondaryColor;
    width: 100%;
    .section__title--services {
        position: relative;
        color: $secondaryColor;
    }

    .section__title::after {
        content: "";
        display: block;
        width: 2em;
        height: 2px;
        margin: 0.25em auto 0.5em;
        background: $secondaryColor;
        opacity: 0.25;
    }

    .section-background-img {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: $mainColor;
        background-blend-mode: multiply;
        background-image: url(../../../assets/laptop-background.jpg);
        background-size: cover;
        filter: blur(2px);
        z-index: -1;
    }

    .services {
        position: relative;
        padding-bottom: 2rem;
        .service {
            h3 {
                font-size: 1.1rem;
                padding-bottom: 1em;
            }
            margin: 0 auto;
            padding: 1em;
            flex: 1;

            color: rgba($secondaryColor, 1);
            font-size: 1rem;
            background-color: rgba(black, 0.8);

            @media (min-width: $tablet) {
                margin: 5px;
                max-width: 500px;
                min-height: 350px;
                font-size: 1.2rem;
                h3 {
                    font-size: 1.5rem;
                }
            }
        }

        @media (min-width: $tablet) {
            display: flex;
            max-width: 1300px;
            margin-right: auto;
            margin-left: auto;
            padding-bottom: 0;
            .service + .service {
                margin-left: 1em;
            }
        }
    }
    @media (min-width: $tablet) {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .wrapper--arrow {
        color: $secondaryColor;
    }
}
