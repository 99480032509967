@import "../../../global.scss";

.section__contact {
    background-color: $mainColor;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100vw;
    @media (min-width: $tablet) {
        flex-direction: row;
    }

    .section__contact--item {
        padding: 1em 0;
    }
    .section__contact--left {
        height: 40%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding-top: 0;
        margin: 0.25rem 0;
        .img--container {
            border-radius: 50%;
            background-color: $secondaryColor;
            width: 60%;
            display: flex;
            align-items: center;
            justify-content: center;
            .left__item--img {
                max-width: 80%;
                height: auto;
            }

            &::after {
                content: "";
                display: block;
                padding-bottom: 100%;
            }
        }

        &::after {
            position: absolute;
            z-index: 10;
            content: "";
            display: block;
            width: 12em;
            height: 2px;
            background: $secondaryColor;
            opacity: 0.25;
            bottom: 0;
        }

        @media (min-width: $tablet) {
            height: auto;
            width: 40%;
            &::after {
                display: block;
                width: 2px;
                height: 12em;
                right: 0;
                bottom: auto;
            }
        }
    }
    .section__contact--right {
        height: 60%;
        display: flex;
        justify-content: center;

        .section__form {
            width: 80%;
            display: flex;
            flex-direction: column;
            .right__item--title {
                text-align: center;
            }
            .right__item--form {
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: 1em 0;

                .form__item {
                    margin: 0.5em 0;
                }

                input {
                    width: 80%;
                    height: 30px;
                    font-size: 14px;
                    background-color: $secondaryColor;
                }
                textarea {
                    width: 80%;
                    height: 200px;
                    font-size: 14px;
                    border: none;
                }
                
            }
        }
        .thank-you-msg {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 70%;
            color: $secondaryColor;
            width: 100%;
            text-align: center;

            .btn__send-another {
                margin: 1rem 0;
            }
            @media (min-width: $tablet) {
            }
        }

        .btn__form {
            width: 40%;
            font-size: 16px;
            cursor: pointer;
            font-weight: 500;
            background-color: $secondaryColor;
            color: $mainColor;

            &:hover {
                background-color: $mainColorLite;
                color: $secondaryColor;
            }
        }

        @media (min-width: $tablet) {
            height: auto;
            width: 60%;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }

        @media (min-width: $desktop) {
            width: 60%;
        }
    }
    @media (min-width: $tablet) {
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 80%;
    }
}
