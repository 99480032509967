@import "../../../global.scss";
// @import "../main-portfolio/projects/projects.scss";

.project__desc {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    bottom: 20px;
    background: $mainColorLite;
    position: absolute;
    top: 0;
    right: -100%;
    z-index: 2;
    transition: right 500ms ease, box-shadow 300ms ease;
    overflow: hidden;

    &.active {
        box-shadow: -5px 5px 15px -3px $mainColorLite;
        right: 0;
    }

    &.hovered {
        box-shadow: -5px 5px 15px -3px $mainColorLite;
        right: -98%;
        @media (min-width: $desktop) {
            right: -78%;
        }
    }

    .project__card {
        flex: 1 1 auto;
        margin: 1rem;
        position: absolute;
        overflow: hidden;
        inset: 0;

        .selectedProject__project-img {
            margin: 0 auto;    
        }

        @media (min-width: $tablet){
            position: relative;
            width: 90%;
            overflow: unset;
        }
    }

    .project__overview {
        position: absolute;
        bottom: 0;
        left: 0;
        margin-bottom: 1rem;
        margin-left: 1rem;
        height: 50px;
        width: 50px;
        overflow: hidden;
        transition-property: width, height;
        transition-duration: 600ms;
        transition-timing-function: ease;

        .project__overview--container {
            position: absolute;
            background-color: $mainColor;
            width: 300px;
            height: 300px;
            border-top-right-radius: 100%;
            padding: 1rem 0;
            bottom: 0;
            transform: translate(-62%, 62%);
            transition-property: transform, box-shadow;
            transition-timing-function: ease;
            transition-duration: 500ms;

            .project__overview--btn {
                position: absolute;
                z-index: 40;
                right: 0;
                background-color: $mainColor;
                height: 30px;
                width: 30px;
                border-radius: 50%;
                line-height: 30px;
                text-align: center;
                color: $secondaryColor;
                transform: translate(-280%, 230%);
                transition-property: transform, box-shadow;
                transition-timing-function: ease;
                transition-duration: 500ms;
                &:after {
                    position: absolute;
                    right: 0;
                    z-index: -1;
                    content: "";
                    height: 17px;
                    width: 17px;
                    background-color: $mainColor;
                    border-radius: 50%;
                    transition: transform 500ms ease 100ms;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }

                &.active {
                    transition: transform 500ms ease;
                    box-shadow: 0px 0px 5px 2px $mainColorLite;
                    transform: translate(-100%, 100%);
                    &:after {
                        box-shadow: 0px 0px 5px 2px $mainColorLite;
                        transform: translate(-175%, 100%);
                    }
                }
            }
            &.active {
                box-shadow: 3px 0px 5px 2px $mainColorLite;
                transform: translate(0, 0);
                margin-right: 1rem;
            }
        }
        &.active {
            transition-duration: 200ms;
            width: calc(300px + 1rem);
            height: calc(300px + 1rem);
        }

        @media (min-width: $tablet) {
            position: relative;
            width: 90%;
            height: auto;
            overflow: unset;
            margin: 0 auto 1rem auto;
            &.active {
                width: 100%;
            }

            .project__overview--container {
                position: relative;
                background-color: $mainColor;
                width: 100%;
                height: 100%;
                border-radius: 0%;
                line-height: 1rem;
                text-align: center;
                color: $secondaryColor;
                transform: translate(0, 0);
                
                &.active {
                    box-shadow: 0;
                    transform: translate(0, 0);
                }

                .project__overview--btn {
                    display: none;
                }
            }
        }
    }

    @media (min-width: $desktop) {
        width: 80%;
        right: -80%;
    }
}

.project__body {
    padding: 1rem 0;
    height: 100%;
    background-color: $secondaryColor;
}
