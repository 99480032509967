$mainColor: LimeGreen;
$mainColorLite: MediumSpringGreen;
$mainColorSplitComp: Chocolate;

$secondaryColor: Cornsilk;
$mainColorOffset: white;
$mainFontWeight: 300;

// click active color
$click-active-dark: black;
$click-active-light: Cornsilk;

// media queries and breakpoints
$mobile: 375px; // iPhone X 375 x 812
$tablet: 768px; // iPad 768 x 1024
$desktop: 1024px;

$navBarHeight: 120px;
$mobileNavBarHeight: 90px;

$viewHeight: calc(100vh - #{$navBarHeight});
$mobileViewHeight: calc(100vh - #{$mobileNavBarHeight});

// colors
// -- languages
$clrReact: rgb(0, 191, 255);
$clrJs: rgb(255, 217, 0);
$clrSass: rgb(255, 20, 145);
$clrHtml5: rgb(220, 20, 60);
$clrNpm: rgb(255, 33, 33);

// -- buttons
$submit: green;
$warning: orange;
$danger: red;

@mixin for_breakpoint($breakpoints) {
    $conditions: ();
    @each $breakpoint in $breakpoints {
        // If the key exists in the map
        $conditions: append(
            $conditions,
            #{inspect(map-get($media_queries, $breakpoint))},
            comma
        );
    }

    @media #{$conditions} {
        @content;
    }
}

.section__title {
    font-size: 40px;
}

.wrapper--arrow {
    bottom: 0;
    z-index: 10;
    animation: blink 1.5s infinite alternate;
}

.grow-box {
    transition: transform 500ms cubic-bezier(0.5, 0, 0.5, 1),
        opacity 250ms linear;
    &:hover {
        transform: scale(1.2);
        opacity: 0.7;
    }
}

// buttons

.btn--trash,
.btn--complete {
    background: rgb(255, 56, 21);
    color: $secondaryColor;
    border: none;
    cursor: pointer;
}
.btn--complete {
    background: rgb(11, 212, 162);
}
.todo__item {
    padding: 0rem 0.5rem;
}

.fa-trash,
.fa-check {
    pointer-events: none;
}

// FontAwesome icons
.project__lang {
    transition: all 500ms ease;
}

.project__lang--react:hover {
    color: rgb(0, 140, 255);
}

.project__lang--js:hover {
    color: rgb(255, 208, 0);
}

.project__lang--sass:hover {
    color: rgb(255, 79, 108);
}

.project__lang--html5:hover {
    color: red;
}

// animations
@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }
    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }
    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }
    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }
    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }
    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}

$colors: $mainColor, $mainColorLite, $secondaryColor, $clrReact, $clrJs, $clrSass, $clrHtml5, $clrNpm ;
@for $i from 1 through length($colors) {
    @keyframes flashing-#{nth($colors, $i)} {
        0%{
            color: $secondaryColor;
        }
        10% {
            color: nth($colors, $i);
        }
        30%, 100% {
            color: $secondaryColor;
        }
    }
}

@keyframes glow {
    to {
        text-shadow: 0 0 5px #fff, 0 0 1px rgb(255, 72, 0),
            0 0 1px rgb(255, 72, 0);
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes squareCorner{
    0% {
        border-top-right-radius: 70%;
    }
    100% {
        border-top-right-radius: 0%;
    }
}
@keyframes roundCorner{
    0% {
        border-top-right-radius: 0%;
    }
    100% {
        border-top-right-radius: 70%;
    }
}

@keyframes blink {
    100% {
        opacity: 0.1;
    }
}

@keyframes blink-cursor {
    0% { opacity: 1; }
    49% { opacity: 1; }
    50% { opacity: 0; }
   100% { opacity: 0; }
}

@-webkit-keyframes blink-cursor {
    0% { opacity: 1; }
    49% { opacity: 1; }
    50% { opacity: 0; }
   100% { opacity: 0; }
}

@-moz-keyframes blink-cursor {
    0% { opacity: 1; }
    49% { opacity: 1; }
    50% { opacity: 0; }
   100% { opacity: 0; }
}