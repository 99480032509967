@import "./global.scss";

.app {
    .sections {
        overflow-x: hidden;
        width: 100%;
        height: $mobileViewHeight;
        position: relative;
        // scrollbar functionality
        scroll-behavior: smooth;
        scroll-snap-type: y mandatory;
        scrollbar-width: none; // firefox
        &::-webkit-scrollbar {
            display: none;
        }

        .section {
            min-height: $mobileViewHeight;
            padding: 0.5rem;
            position: relative;

            .section__title {
                padding-top: 0.5em;
                font-size: 1.5rem;

                @media (min-width: $tablet){
                    font-size: 2rem;
                }
            }
            @media (min-width: $tablet) {
                min-height: $viewHeight;
            }
            @media (min-width: $tablet) {
                padding: 4em 10rem;
            }
        }
        @media (min-width: $tablet) {
            height: $viewHeight;
        }
    }

    .offsetNavBar {
        margin-top: $mobileNavBarHeight;
        @media ( min-width: $desktop) {
            margin-top: $navBarHeight;
        }
    }

    .wrapper__link--container {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        margin-bottom: 0.5em;
        width: 100%;
        text-align: center;
    }

    img {
        display: block;
        max-width: 100%;
    }
}
