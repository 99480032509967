@import "../../../global.scss";

.project__body--todo {
    color: $mainColor;
    min-height: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.project__header {
    font-size: 1rem;

    @media (min-width: $tablet) {
        font-size: 2rem;
    }
}

.project__header {
    display: flex;
    align-items: center;
    padding: 0 1em;
}

.project__form .todo--input,
.todo-button {
    padding: 0.5rem;
    font-size: 1rem;
    border: none;
    background: $secondaryColor;
    @media (min-width: $tablet) {
        font-size: 2rem;
    }
}

.todo__container {
    padding: 0 1rem;
    width: 100%;
}

.todo__list {
    list-style: none;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 500px;
    // scrollbar-width: none;
    // &::-webkit-scrollbar {
    //     display: none;
    // }
    width: 100%;
    padding: 0;
}

.todo {
    background: $mainColorLite;
    font-size: 0.8rem;
    color: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 1s ease;
    width: 100%;

    &+ .todo {
        margin-top: 0.25rem;
    }

    .btn--trash,
    .btn--complete {
        padding: 0.2rem;
        font-size: 0.8rem;
    }

    @media (min-width: $tablet) {
        margin-top: 1rem;
        font-size: 1.5rem;
        
        &+ .todo {
            margin-top: 1rem;
        }

        .btn--trash,
        .btn--complete {
            padding: 1rem;
            font-size: 1.5rem;
        }
    }
}

.filter-todo {
    padding: 0 1rem;
    background-color: $secondaryColor;
    color: black;
    @media (min-width: $tablet) {
        padding: 1rem;
    }
}
.todo li {
    flex: 1;
}

.btn--trash,
.btn--complete {
    padding: 1rem;
    font-size: 1rem;
}

.fall {
    transform: translateY(10rem) rotateZ(20deg);
    opacity: 0;
}

.completed {
    text-decoration: line-through;
    opacity: 0.5;
}

.project__form--todo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 1rem;

    .project__form--item + .project__form--item {
        margin-left: 1rem;
    }
}

.project__form--input {
    flex: 2;
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 50px;
    .todo--input {
        border: 2px solid $mainColor;
        width: 100%;
    }
}

.todo-button {
    color: $secondaryColor;
    background: $mainColorLite;
    cursor: pointer;
    transition: all 0.3s ease;
}
.todo-button:hover {
    background: $mainColor;
    color: $secondaryColor;
}

@media (max-width: $desktop) {
    .project__form--todo {
        flex-direction: column;

        .project__form--item {
            margin: 0.5rem 0;
            + .project__form--item {
                margin-left: 0;
            }
        }
        .project__form--select {
            width: 100%;
        }
    }
}

/*CUSTOM SELECTOR */
.project__form--select {
    background-color: lightblue;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    outline: 0;
    box-shadow: none;
    background-image: none;
    flex: 1;
}

/* Custom Select */
.form__select {
    height: 40px;
    line-height: 40px;
    position: relative;
    overflow: hidden;
    color: $mainColor;
    font-family: "Poppins", sans-serif;
    cursor: pointer;
    width: 100%;
    border: 2px solid $mainColor;
}
/* Arrow */
.form__select::after {
    content: "\25BC";
    position: absolute;
    top: 0;
    right: 0;
    padding: 1rem;
    background: $mainColor;
    cursor: pointer;
    pointer-events: none;
}
/* Transition */
/*
  .select:hover::after {
    transform: scale(1.5);
  }
  */
