@import "../../../global.scss";

.section__projects {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .section__title--projects {
        @media (min-width: $tablet) {
        }
    }

    .projects {
        position: relative;
        .project__item {
            text-align: center;
            padding: 1em;
            margin: 1em 0;
            background: $mainColor;
            border-radius: 5px;

            .project__title {
                display: none;
            }

            .project__langs {
                margin-top: 0.5rem;
                font-size: 2rem;
                .project__lang + .project__lang {
                    margin-left: 2rem;
                }
            }
        }
    }

    @media (min-width: $desktop) {
        align-items: flex-end;
        .section__title--projects {
            margin-bottom: 1rem;
            align-self: flex-start;
            overflow: hidden;
        }
        .projects {
            width: 100%;
            transition: all 800ms ease;

            &:after {
                content: "";
                position: absolute;
                display: block;
                width: calc(100% + 15rem);
                height: 2px;
                background: $mainColor;
                top: 0;
            }

            .project__item {
                margin: 2em 0;
                height: 200px;
                position: relative;
                display: grid;
                grid-template-columns: auto 1fr;
                grid-template-rows: auto 1fr;
                grid-template-areas:
                    "img title"
                    "img langs";
                justify-items: start;
                column-gap: 1rem;
                row-gap: 1rem;
                right: -40%;
                transition: all 600ms ease;

                &:after {
                    position: absolute;
                    display: block;
                    content: "";
                    width: 5px;
                    background: white;
                    border-radius: 10px;
                    height: 30%;
                    margin: auto 0;
                    left: -1rem;
                    transition: all 300ms ease;
                }

                &:hover:after,
                &.selected:after {
                    background: $mainColor;
                    height: 95%;
                }

                &.selected:after {
                    width: 4px;
                    box-shadow: 0px 0px 5px 2px $mainColorLite;
                }

                &:hover {
                    cursor: pointer;
                    background: $mainColorLite;
                    transform: translateX(-30px);
                    .project__lang--react {
                        color: $clrReact;
                    }

                    .project__lang--js {
                        color: $clrJs;
                    }

                    .project__lang--sass {
                        color: $clrSass;
                    }

                    .project__lang--html5 {
                        color: $clrHtml5;
                    }

                    .project__lang--npm {
                        color: $clrNpm;
                    }
                }

                &:hover,
                &.selected {
                    background: $mainColorLite;
                    transform: translateX(-30px);
                }

                &.extend {
                    right: 0;
                }

                .project__img {
                    grid-area: img;
                    height: 100%;
                    width: auto;
                    margin: 0;
                }
                .project__title {
                    white-space: nowrap;
                    display: block;
                    margin-top: 1em;
                    grid-area: title;
                    font-size: 3rem;
                    margin: 0;
                }
                .project__langs {
                    margin: 0;
                    grid-area: langs;
                    .project__lang {
                        font-size: 3rem;
                        transition: all 700ms ease;
                    }
                    .project__lang + .project__lang {
                        margin-left: 2rem;
                    }
                }
            }
        }
    }
}
