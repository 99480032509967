@import "../../../../global.scss";

.project__body-swipeable-news {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    text-align: center;
    font-size: var(--fs-h3);
    align-items: center;
}

.swipeable-news-logo {
    height: 40vmin;
    pointer-events: none;
}

.swipeable-news-container {
    width: 100%;
    flex: 1;
}

.swipeable-news-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // overflow: auto;
    padding-bottom: 10px;
    color: $mainColor;
    text-align: center;

    .swipeable-news-title {
        font-size: 22px;
        @media (min-width: $tablet) {
            font-size: 30px;
        }
    }

    .swipeable-news-sub-title {
        font-size: 16px;
        @media (min-width: $tablet) {
            font-size: 22px;
        }
    }
}

button {
    border: none;
    background: var(--clr-accent);
}

button:hover {
    cursor: pointer;
}

@media (prefers-reduced-motion: no-preference) {
    .swipeable-news-logo {
        animation: swipeable-news-logo-spin infinite 20s linear;
    }
}

// Social Cards Body

.SocialCards-body {
    display: flex;
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;
    @media (max-width: $tablet) {
        flex-direction: column;
        overflow-x: auto;
        overflow-y: auto;
        max-height: 60vh;
    }
}

// Social Card Selector
.category-selector__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    user-select: none;
}

.category-selector__section {
    font-size: 1rem;
    margin: 1px;
}

.category-selector--select-body {
    position: relative;
    border: 2px solid $mainColor;
    border-radius: 5px;
    display: flex;
    height: 30px;
    margin-top: 3px;
    align-items: center;
    overflow: hidden;

    .category-selector--select {
        background-image: none;
        margin: 0 5px;
        position: relative;
        display: flex;
        align-items: center;

        select {
            cursor: pointer;
            -webkit-appearance: none;
            -moz-appearance: none;
            -ms-appearance: none;
            appearance: none;
            box-shadow: none;
            border: none;
            outline: none;
            width: 8rem;
            font-size: 1rem;
        }

        &:after {
            content: "\25BC";
            position: absolute;
            right: 0;
            font-size: 0.8rem;
            background: $mainColor;
            color: $secondaryColor;
            pointer-events: none;
            padding: 1px 2px;
        }
    }

    .category-selector__add-selected-category-btn {
        background: $mainColor;
        color: $secondaryColor;
        padding: 5px;
        width: 20px;
        &:hover {
            cursor: pointer;
            background: var(--clr-dark);
            color: var(--clr-light);
        }
    }
}

.category-selector__current-selected--list-body {
    display: flex;
    justify-content: center;
    color: black;
    margin-bottom: 5px;
}

.category-selector__current-selected--list {
    list-style-type: none;
    display: flex;
    margin: 0;
    padding: 0;
    max-width: 300px;
    overflow-x: auto;

    .selected-category--item {
        display: flex;
        text-align: center;
        line-height: 1rem;
        border: 2px solid $mainColor;
        border-radius: 5px;
        background: $secondaryColor;
        margin: 1px;

        .selected-category__name {
            padding: 3px 7px 5px;
        }
        .selected-category__delete-item-btn {
            border: none;
            background: $mainColor;
            padding: 4px 10px;

            &:hover {
                cursor: pointer;
                background: var(--clr-dark);
                color: var(--clr-light);
            }
        }
    }
    .selected-category--item + .selected-category--item {
        margin-left: 10px;
    }
}

.swipeable-news-credits {
    width: 100%;
    text-align: center;
    font-size: 0.8rem;
}

@media (min-width: 800px) {
    .category-selector__body {
        display: flex;
        flex-direction: row;
        align-items: center;
        user-select: none;
        width: 40vw;
        justify-content: space-evenly;
    }

    .category-selector__section {
        align-self: start;
        font-size: 1rem;
        margin: 0;
    }

    .category-selector__current-selected--list {
        justify-content: flex-end;
    }
}

.active-news {
    border: 2px solid var(--clr-dark) !important;
}
