@import "../../../../global.scss";

.social-card--body {
    margin: 1rem auto;
    .social-card--proxy-body {
        margin: 15px 10px 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: black;
        padding: 10px;
        background: rgba($mainColor, 0.75);
        border-radius: 5px;
        min-width: 400px;
        @media (max-width: $tablet){
            min-width: 0;
        }
    }
    @media (max-width: $tablet){
        margin: 0;
    }
}

@media (min-width: 800px) {
    .social-card--body {
        .social-card--proxy-body {
            width: 400px;
            margin: 0.5rem;
        }
    }
}

@keyframes fadeIn {
    0% {
        left: 100;
    }
    100% {
        left: 1;
    }
}

.app-title__body {
    padding: 2rem 0;

    .app-title {
        margin: 0;
        padding: 0;
    }

    .app-sub-title {
        margin: 0;
        padding: 0;
    }
}

.app-credits {
    font-size: 1rem;
    font-style: italic;
    padding: 1rem;
}

.SocialCard__body {
    position: relative;
    margin-top: 5px;
    min-height: 20px;
}

.SocialCard__body + .SocialCard__body {
    margin-top: 10px;
}

.Wrapper__body {
    position: relative;
}

.Wrapper {
    position: relative;
    transition: opacity 0.25s ease-out, height 0.5s ease-out 0.2s;
    width: 100%;
    height: 180px;
    display: block;
    user-select: none;
    color: var(--clr-light);

    @media (max-width: $tablet) {
        height: auto;
    }
}

.WrapperOut {
    height: 0;
    opacity: 0;
}

.Background {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-right: 16px;
    color: black;
    background-color: rgba(255, 89, 89, 1);
    box-sizing: border-box;
    border-radius: 11px;
}

.UndoSwipe {
    background-color: $mainColorSplitComp;
    font-size: 1rem;
    text-align: right;
    padding: 3px 10px;
    z-index: -2;
    width: 100%;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    position: absolute;
    top: 0;
    transition: opacity 1s;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-style: italic;

    .Preview__text {
        font-size: 0.8rem;

        padding-right: 1rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.UndoSwiped__text {
    border-left: 2px solid var(--clr-dark);
    padding-left: 0.5rem;
}

.UndoSwiped__text:hover {
    cursor: pointer;
    color: var(--clr-light);
}

.BouncingSwipableListItem {
    transition: all 0.5s ease-out;
    width: 100%;
    align-items: center;
    box-sizing: border-box;
    height: 100%;
    background: var(--clr-dark);
    border-radius: 10px;

    display: grid;
    grid-template-columns: 40% 1fr;
    grid-template-rows: 30% 1fr;
    grid-template-areas:
        "img header"
        "img description";
    column-gap: 10px;
    row-gap: 5px;
    overflow: hidden;
}

.SwipableListItem {
    width: 100%;
    align-items: center;
    box-sizing: border-box;
    height: 100%;
    background: var(--clr-dark);
    border-radius: 10px;
    transition: background-color 0.5s ease;

    display: grid;
    grid-template-columns: 40% 1fr;
    grid-template-rows: 30% 1fr;
    grid-template-areas:
        "img header"
        "img description";
    column-gap: 10px;
    row-gap: 5px;
    overflow: hidden;
    position: relative;

    @media (max-width: $tablet) {
        grid-template-areas: "img description";
        grid-template-rows: 1fr;
        height: auto;
        .SocialCard-section__header-body {
            display: none;
            .SocialCard-section__header-icon {
                background-color: coral;
            }
        }
        .SocialCard-section__text-body {
            .SocialCard-section__text-body--description {
                display: none;
            }
        }
    }
}

.social-card--title {
    text-transform: capitalize;
    font-size: 1.5rem;

    @media (max-width: $tablet){
        font-size: 1.2rem;
    }
}

.SocialCard-section {
    display: flex;
    text-align: left;
}

// Social Card Header
.SocialCard-section__header-body {
    grid-area: header;
    align-self: start;
    font-size: 1rem;
    margin-top: 10px;

    .SocialCard-section__header-icon {
        height: 40px;
        width: 40px;
        background: $mainColor;
        position: relative;
        .SocialCard-section__header-icon--img {
            position: absolute;
            max-width: 70%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .SocialCard-section__header-text {
        margin-left: 0.5rem;
        max-height: 20px;
        .SocialCard-section__header-text--source {
            font-weight: bold;
            font-size: 0.95rem;
            width: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .SocialCard-section__header-text--publish-date {
            font-size: 0.8rem;
        }
    }
}

@media (min-width: 800px) {
    .SocialCard-section__header-text--source {
        font-size: 1.2rem;
    }
}

// Social Card Image
.SocialCard-section__img-body {
    grid-area: img;
    height: 100%;

    .SocialCard-section__img-body--img-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        backdrop-filter: blur(3px);
        overflow: hidden;
        height: 100%;
        width: 100%;
        text-decoration: none;

        .SocialCard-section__img-body--img {
            height: auto;
            text-align: center;
            color: var(--clr-accent);
            @media (max-width: $tablet){
                background-image: url(../../../../assets/ceen-square.png);
                background-repeat: no-repeat;
                background-position: center;
                background-size: 40px 40px;
                max-height: 50px;
            }
        }

        .SocialCard-section__img-body--img:hover {
            cursor: pointer;
            color: var(--clr-light);
        }
    }
}

// Social card Text
.SocialCard-section__text-body {
    flex-direction: column;
    grid-area: description;
    align-self: start;
    margin-top: 2px;
    padding-right: 15px;

    .SocialCard-section__text-body--title {
        font-weight: bold;
        font-size: 1rem;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        a {
            color: var(--clr-light);
        }
        &:hover,
        a:hover {
            color: var(--clr-accent);
        }

        @media (max-width: $tablet){
            margin-top: 2px;
            font-size: 0.8rem;
        }
    }

    .SocialCard-section__text-body--title:hover {
        cursor: pointer;
        text-decoration: underline;
    }

    .SocialCard-section__text-body--author {
        font-size: 0.7rem;
        font-style: italic;
    }

    .SocialCard-section__text-body--description {
        font-size: 0.7em;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
}

.SwipableListItem.warning {
    background: rgb(206, 25, 25);
    color: var(--clr-light);
}

// Deleted
.SocialCard-section__delete-body {
    background: orange;
}
