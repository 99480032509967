@import "../../../global.scss";

.nav__menu {
    width: 300px;
    height: 100vh;
    background-color: $mainColor;
    position: fixed;
    right: -300px;
    z-index: 100;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    transition: all 0.5s ease;
    color: $secondaryColor;
    
    .nav__list {
        list-style: none;
        font-size: 2em;
        font-weight: $mainFontWeight;
        margin-top: 5rem;
        width: 70%;
        .nav__item {
            margin: 8px 0px;
            .nav__link {
                color: inherit;
                text-decoration: none;
            }
            &:hover {
                font-weight: 500;
            }
        }
    }

    &.active {
        right: 0;
    }
}
