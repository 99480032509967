@import "../../../global.scss";

.nav {
    width: 100%;
    height: $mobileNavBarHeight;
    position: fixed;
    top: 0;
    background: linear-gradient(to right, white 34%, #{$mainColor} 65%);
    background-size: 300% 100%;
    background-position: left bottom;
    color: $mainColor;
    transition-property: color, background-color, background-position;
    transition-duration: 0.5s;
    transition-timing-function: ease;
    font-weight: $mainFontWeight;

    .nav__wrapper {
        padding: 10px 30px;
        margin: 5px 0px;
        display: flex;
        justify-content: center;
        height: 100%;

        .nav__left {
            margin: 0.5em 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .logo--img img {
                max-width: 2rem;
                margin-right: 0.75rem;
                display: none;
                @media (min-width: $desktop) {
                    display: block;
                }
            }
            .logo--text {
                text-align: center;
                min-width: 220px;
                font-size: 1.5rem;
                font-weight: 400;
                text-decoration: none;
                margin-right: 15px;
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-image: linear-gradient(
                    0deg,
                    rgb(255, 72, 0),
                    rgb(255, 72, 0) 50%,
                    $mainColor 50%
                );
                background-size: 100% 200%;
                background-position: 0% 0%;

                &:hover {
                    // color transition
                    transition: all 1.5s cubic-bezier(0, 0, 0.23, 1);

                    background-position: 100% 100%;

                    font-size: 1.6rem;

                    /* Start the shake animation and make the animation last for 0.5 seconds */
                    animation: shake 1s infinite,
                        glow 1s ease-in-out infinite alternate;

                    /* When the animation is finished, start again */
                    animation-iteration-count: 1.2s;
                }
            }
            .itemContainer__body {
                margin-top: 1em;
                font-size: 0.8rem;
                display: flex;
                .itemContainer__items {
                    display: flex;
                    align-items: center;
                    padding: 0px 2px;
                    .itemContainer__email {
                        margin-left: 1em;
                        display: flex;
                        align-items: center;
                        text-decoration: none;
                        color: $mainColor;
                        transition: all 200ms ease;
                        .itemContainer--item + .itemContainer--item {
                            margin-right: 10px;
                        }
                        .itemContainer--text {
                            margin-left: 5px;
                        }
                        &.active {
                            color: $secondaryColor;
                        }
                    }
                }
                @media (min-width: $tablet) {
                    font-size: 1rem;
                }
            }
        }

        .nav__right {
            padding: 0.5em;
            background: transparent;
            border: 0;
            cursor: pointer;
            position: absolute;
            right: 1rem;
            z-index: 1000;
            top: 1.5rem;

            .hamburger {
                display: block;
                position: relative;
            }
            .hamburger,
            .hamburger::before,
            .hamburger::after {
                background: $mainColor;
                width: 2em;
                height: 3px;
                border-radius: 1em;
                transition: transform 250ms ease-in-out;
            }
            .hamburger::before,
            .hamburger::after {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
            }
            .hamburger::before {
                top: 6px;
            }
            .hamburger::after {
                bottom: 6px;
            }

            @media (min-width: $tablet) {
                right: 1.5rem;
                top: auto;
            }
        }
        @media (min-width: $tablet) {
            align-items: center;
        }
    }

    &.active {
        background-position: right bottom;
        color: $secondaryColor;
        .nav__wrapper {
            .logo--text {
                background-image: linear-gradient(
                    0deg,
                    rgb(255, 72, 0),
                    rgb(255, 72, 0) 50%,
                    $secondaryColor 50%
                );
            }
        }

        .nav__right > .hamburger,
        .nav__right > .hamburger::before,
        .nav__right > .hamburger::after {
            background: white;
        }
        .nav__right > .hamburger {
            transform: rotate(0.625turn);
        }
        .nav__right > .hamburger::after {
            opacity: 0;
        }
        .nav__right > .hamburger::before {
            transform: rotate(90deg) translateX(-6px);
        }
    }
}

@media (min-width: $desktop) {
    .nav {
        height: $navBarHeight;
        .nav__wrapper {
            justify-content: space-between;
            .nav__left {
                flex-direction: row;
                .logo {
                    font-size: 2.5rem;
                }
                .itemContainer__body {
                    margin-top: 0;
                }
            }
        }
    }
}
