@import "../../../global.scss";

.section__intro {
    background-color: $secondaryColor;
    display: flex;
    flex-direction: column;
    height: 100%;
    text-align: center;
    .left {
        flex: 6;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        .left__wrapper {
            display: flex;
            justify-content: center;
            padding: 1em 0;
            width: 100%;
            .left__img {
                border-radius: 50%;
                max-width: 300px;
                padding: 0 1em;

                @media (min-width: $tablet) {
                    max-width: 100%;

                    border-radius: 0%;
                    border-top-left-radius: 50%;
                    border-bottom-left-radius: 50%;
                    border-top-right-radius: 50%;
                }
            }
        }

        @media (min-width: $tablet) {
            flex: 5;
        }
    }
    .right {
        color: $mainColor;
        flex: 6;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        .right__wrapper {
            // height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            .right__wrapper--intro {
                font-size: 30px;
            }
            .right__wrapper--name {
                font-size: 40px;
            }
            .right__wrapper--title {
                font-size: 20px;
                display: flex;
                align-items: center;
                flex-direction: column;
                .right__wrapper--title-animation {
                    margin-left: 0.4rem;
                    font-size: 20px;
                    display: inline-block;
                    line-height: 20px;
                    color: Chocolate;
                }
                .ityped-cursor {
                    font-size: 22px;
                    opacity: 1;
                    -webkit-animation: blink-cursor 1.2s infinite;
                    -moz-animation: blink-cursor 1.2s infinite;
                    animation: blink-cursor 1.2s infinite;
                }
                @media (min-width: $tablet) {
                    flex-direction: row;
                }
            }
            .wrapper__link--container {
                .right__wrapper--link {
                    bottom: 0;
                    color: $mainColor;

                    &:hover {
                        color: $click-active-dark;
                    }

                    &:active {
                        color: $mainColor;
                    }

                    .wrapper--arrow {
                        left: 1.2em;
                    }
                }
                @media (min-width: $tablet) {
                    text-align: left;
                }
            }

            @media (min-width: $tablet) {
                align-items: flex-start;
                justify-content: center;
            }
        }
        @media (min-width: $tablet) {
            padding-top: 20vh;
            align-items: flex-start;
            justify-content: center;
        }
    }

    @media (min-width: $tablet) {
        flex-direction: row;
        text-align: left;
    }
}
